/* general : end */
@media screen and (max-width: 1180px) and (min-width: 1025px) {
  html {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 700px) and (min-width: 571px) {
  html {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 570px) and (min-width: 361px) {
  html {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 350px) {
  html {
    font-size: 14px !important;
  }
}