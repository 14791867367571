@media screen and (max-width: 570px) {
  .center {
    padding: 0 1.25rem;
  }

  .register_btn {
    &--main {
      width: 100% !important;
      max-width: none !important;
      height: 3.125rem !important;
      line-height: 3.125rem !important;
      font-size: 1.375rem !important;
      font-weight: 600;

      .cursor {
        top: 1.5625rem;
        right: 0.375rem;
      }
    }

    &--header {
      height: 2rem;
      line-height: 2rem;
      max-width: 9.5rem;
      font-size: 0.8125rem;
    }
  }

  .header {
    height: 3rem;

    .menu {
      display: flex;
      flex-direction: row;
      align-items: center;

      &--open {
        justify-content: flex-start;

        svg {
          transform: scale(.8);
        }
      }

      &--close {
        justify-content: space-around;
        top: 0.5rem;
        right: 0.5rem;
      }
    }

    nav {
      width: 16.5625rem;

      ul {
        padding: 3rem 0 0;

        li {
          text-align: left;
          padding: 0 0 0.5rem 2.25rem;
          font-size: 1rem;
        }
      }
    }
  }

  .timeout {
    padding: 24px 0 0;

    &__header {
      font-size: 1rem;
      text-align: center;
    }

    &__date {
      justify-content: space-around;
      max-width: 15.75rem;
      margin: 0 auto;
    }

    &__item {
      font-size: 1.375rem;
      letter-spacing: -5%;
      width: 3.375rem;
      height: 3.375rem;

      span {
        font-size: 0.5rem;
        letter-spacing: -3%;
      }
    }
  }

  article > .center {
    position: relative;

    &:after {
      position: absolute;
      display: block;
      content: '';
      background: linear-gradient(270deg, rgba(25, 35, 45, 0) 10.09%, rgba(25, 35, 45, 0.58) 29.17%, #19232D 87.7%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .intro {
    padding-top: 0.25rem;
    padding-bottom: 2.5rem;
    flex-direction: column;

    &__logo {
      display: block;
    }

    &__left {
      padding: 0;
      width: 100%;

      &__txt {
        padding: 0.75rem 3.5rem 5.25rem 0;
      }
    }

    &__right {
      position: absolute;
      top: 0;
      left: 0;

      &__face--tablet {
        position: absolute;
        top: 5rem;
        left: 5rem;
      }

      &__btr--tablet {
        top: 1rem;
        left: -9rem;
      }
    }
  }

  .price {
    padding: 1.375rem 0 2.375rem;

    &__main {
      img {
        max-width: 8.875rem;
      }
    }

    &__header {
      font-size: 1.25rem;
    }

    &__money {
      font-size: 3.25rem;
    }

    &__organizer {
      padding: 2.5rem 1rem 1.875rem;

      &__logo {
        flex-shrink: 0;
        width: 4.375rem;

        svg {
          display: block;
          width: 100;
          height: auto;
        }
      }

      &__txt {
        padding-left: 1.25rem;
        font-size: 1rem;

        br {
          display: none;
        }

        span {
          font-weight: 700;
        }
      }
    }

    &__whois {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: space-around;

        svg {
          width: 4.0625rem !important;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span:nth-child(2) {
          padding-left: 1.25rem;
          display: block;
          text-align: left;
          font-size: 0.8125rem;

          br {
            display: none;
          }
        }
      }
    }
  }

  .task {
    &:before {
      position: absolute;
      width: 32.9375rem;
      height: 4.375rem;
      top: -1px;
      left: 0;
      margin: 0;
      background-size: 100% auto;
      background-position: center top;
    }

    .center {
      flex-direction: column;
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin: 0 auto;
      max-width: 15.125rem;
    }

    &__right {
      margin-top: -1rem;
      padding-top: 0;
      z-index: 1;

      p {
        font-size: 1rem;
        margin-bottom: 1.35rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__header {
      font-size: 1.5625rem;
    }

    &__likes {
      margin-top: 2.875rem;

      svg {
        display: block;
        width: 100%;
        max-width: 12.5rem;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  .members {
    padding: 1.875rem 0 5.5rem;

    .center {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__header,
    &__who {
      text-align: left;
    }

    &__header {
      font-size: 1.5625rem;
    }

    &__who {
      font-size: 1rem;
      margin-bottom: 1.35rem;
      margin-bottom: 0;
    }

    &__persons {
      margin-top: 1.625rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__item {
      padding: 0 !important;

      img {
        display: none;
      }

      .members__txt {
        padding: 0;
        text-align: left;

        span {
          display: block;
        }

        strong {
          padding-bottom: 0.8rem;
        }
      }

      &:nth-child(1) {
        background-image: url("./../img/members_free.png");
        background-size: 11.5rem auto;
        background-repeat: no-repeat;
        background-position: -0.625rem -0.625rem;
        padding-bottom: 2rem !important;
        margin-bottom: -2rem;

        > span {
          padding-left: 10rem;
        }
      }

      &:nth-child(2) {
        padding-top: 3.1rem !important;
        background-image: url("./../img/members_designers.png");
        background-size: 11.1875rem auto;
        background-repeat: no-repeat;
        background-position: 100% center;

        > span {
          padding-right: 9.5rem;
        }
      }
    }
  }

  .attention {
    padding: 1.875rem 0 4.5rem;

    &__header {
      font-size: 3.4375rem;
    }

    p {
      font-size: 0.9375rem;
    }

    &__divider {
      margin: 1.875rem 0 2.5rem;
    }

    &__btn {
      padding: 0 1.25rem;
    }
  }

  .tool {
    padding: 1.875rem 0 4.375rem;

    &__header {
      font-size: 1.5625rem;
      text-align: left;
    }

    p {
      text-align: left;
    }

    &__logic {
      padding: 1.875rem 0 3.125rem;
    }
  }

  .steps {
    padding: 1.875rem 0 4.375rem;
    background-size: 121.5625rem 94.1875rem;
    background-position: -65.5rem 0;
    position: relative;

    .center {
      z-index: 1;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(180.04deg, rgba(0, 0, 0, 0) 49.97%, rgba(70, 62, 53, 0.62) 99.96%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 62.97%, rgba(255, 255, 255, 0) 100%);
    }

    &__header {
      text-align: left;
      font-size: 1.5625rem;
      margin-bottom: 1.25rem;
    }

    &__column {
      > div {
        padding: 0;
      }

      p {
        font-size: 0.9375rem;
      }

      .num {
        font-size: 7.5rem;
        opacity: 0.7;
      }

      &--1 {
        p {
          padding-right: 2rem;
        }

        .num {
          position: absolute !important;
          top: -1rem !important;
          left: 88% !important;
        }
      }

      &--2 {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }

    &__winners {
      padding-top: 2.875rem;

      strong {
        padding-bottom: 1.25rem;
        font-size: 1.375rem;
      }

      p {
        font-size: 0.9375rem;
        font-weight: 700;
      }
    }

    &__win {
      font-size: 22px;
      padding-top: 30px;
    }
  }

  .bingo {
    padding: 20px 0 30px;

    &__main {
      img {
        width: 8.875rem;
      }

      strong {
        font-size: 3.8125rem;
        padding-bottom: 1.875rem;
      }
    }

    &__header {
      margin: -.5rem 0 0.5rem;
      font-size: 1.25rem;
    }

    &__items {
      flex-wrap: wrap;
    }

    &__item {
      &--1 {
        order: -1;
        width: 100%;
        padding-bottom: 1.5rem;
        font-size: 1.3125rem;

        span {
          font-size: 2.875rem;
        }

        svg {
          width: 11.875rem;
        }
      }

      &--2,
      &--3 {
        width: 50%;
        font-size: 0.9375rem;

        span {
          font-size: 1.875rem;
        }

        svg {
          width: 8.125rem;
        }
      }
    }
  }

  .time {
    padding: 1.875rem 0 6.25rem;

    &__header {
      font-size: 20px;
    }

    &__list {
      &:after {
        left: calc(~"36% + 2px");
      }

      li {
        font-size: 0.9375rem;

        .donut {
          margin: 0 1rem;
        }
      }
    }
  }

  .helpful {
    padding: 1.875rem 0;

    &__h {
      font-size: 1.875rem;
      padding-bottom: 1.875rem;
    }

    &__list {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      li {
        width: 100%;
        margin-bottom: 3.75rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .l-winners {
    padding: 1.875rem 0 4.0625rem;

    &__h {
      font-size: 1.5625rem;
      text-align: center;
      padding-bottom: 2.375rem;
    }

    &__list {
      flex-wrap: wrap;

      img {
        max-width: 9.6875rem;
        margin: 0 auto;
      }

      li {
        margin: 0;
      }

      li:nth-child(1) {
        width: 100%;
        margin-bottom: 1.625rem;
      }

      li:nth-child(2),
      li:nth-child(3) {
        width: 50%;
      }
    }
  }

  .contacts {
    padding: 60px 0 30px;

    &__img {
      z-index: 2;
    }

    &__btn {
      padding-bottom: 2.25rem;
    }

    &__who {
      flex-direction: column;
      padding-bottom: 3.75rem;

      a {
        font-size: 1.375rem;
        margin-bottom: 3.125rem;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 62px;
          height: 62px;
          margin-right: 0.875rem;
        }
      }
    }

    &:before {
      position: absolute;
      width: 32.9375rem;
      height: 4.375rem;
      top: -1px;
      left: 0;
      margin: 0;
      background-size: 100% auto;
      background-position: center top;
      z-index: 2;
    }

    &__person {
      font-size: 1.375rem;

      strong {
        display: block;
        padding-bottom: 0.625rem;

        + br {
          display: none;
        }
      }
    }
  }
}