/* tablet: starts */
@media screen and (max-width: 1024px) {
  .center {
    padding: 0 1.875rem;
  }

  .menu-shown {
    overflow: hidden;

    nav {
      display: block !important;
    }

    .overlay {
      display: block;
    }
  }

  .register_btn--main {
    width: 16.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
    font-size: 1.375rem;

    .cursor {
      top: 1.4rem;
      right: -.8rem;

      svg {
        width: 2.25rem;
        display: block;
        height: auto;
      }
    }
  }

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 10;
  }

  .header {
    .menu {
      display: block;
      cursor: pointer;

      &--open {}

      &--close {
        position: absolute;
        z-index: 2;
        width: 2.125rem;
        height: 2.125rem;
        top: 1.25rem;
        right: 1.75rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        svg {
          display: block;
          width: 1.1875rem;
          height: auto;
        }
      }
    }

    nav {
      display: none;
      max-width: none;
      width: auto;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 21.375rem;
      z-index: 100;

      ul {
        background: #19232D;
        height: 100%;
        flex-direction: column;
        padding-top: 4.25rem;
        justify-content: flex-start;

        li {
          font-size: 1.125rem;
          line-height: 2.4;
          text-align: right;
          padding-right: 4.25rem;
        }
      }
    }
  }

  .intro {
    &__logo {
      svg {
        display: block;
        max-width: 8rem;
        width: 100%;
        height: auto;
      }
    }

    &__left {
      padding-top: 3rem;
      max-width: none;
      z-index: 2;

      &__txt {
        max-width: 19.625rem;
        padding-bottom: 3.125rem;

        p {
          font-size: 1rem;
          margin-top: 1rem;
        }
      }

      .register_btn {
        width: 14rem;
        height: 2.75rem;
        line-height: 2.75rem;
        font-size: 1.125rem;

        .cursor {
          top: 1.4rem;
          right: -.8rem;

          svg {
            width: 2.25rem;
            display: block;
            height: auto;
          }
        }
      }
    }

    &__right {
      display: block;
      padding-top: 2rem;

      &__face {
        display: none;

        &--tablet {
          display: block;
          width: 24.5rem;
          height: auto;
          margin: -1rem -2rem 0 0 !important;
        }
      }

      &__btr {
        display: none;

        &--tablet {
          display: block;
          width: 33.3125rem;
          height: auto;
          top: -.25rem;
          left: -8rem;
        }
      }
    }
  }

  .timeout {
    padding-top: 2.25rem;

    &__header {
      padding-bottom: 1rem;
      font-size: 0.8125rem;
    }

    &__item {
      font-size: 1.125rem;
      width: 2.875rem;
      height: 2.875rem;
      border-width: 0.125rem;

      span {
        font-size: 0.5rem;
      }
    }
  }

  .price {
    padding: 2.5rem 0 3.125rem;
    background-size: cover;

    .center {
      width: auto;
      max-width: none;
    }

    &__main {
      img {
        display: block;
        width: 100%;
        max-width: 11.5rem;
        height: auto;
        margin: 0 auto;
      }
    }

    &__header {
      font-size: 1.875rem;
    }

    &__money {
      font-size: 4.625rem;
      line-height: 1;
    }

    &__organizer {
      padding-top: 2rem;

      &__logo {
        svg {
          display: block;
          width: 100%;
          height: auto;
          max-width: 5rem;
        }
      }

      &__txt {
        padding-left: 2rem;
        font-size: 1rem;
      }
    }

    &__whois {
      padding: 2rem 0 0;
      max-width: none;
      width: 100%;

      &__icon {
        padding-bottom: 1rem;
        min-height: 7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        svg {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 auto;
        }

        &:nth-child(1) {
          svg {
            width: 5.75rem;
          }
        }

        &:nth-child(2) {
          svg {
            width: 5.3125rem;
          }
        }

        &:nth-child(3) {
          svg {
            width: 4.375rem;
          }
        }
      }
    }
  }

  .task {
    position: relative;

    .center {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 7.375rem;
      top: -1px;
      left: 0;
      margin: 0;
      background-size: 100% auto;
      background-position: center top;
    }

    &__left {
      width: 50%;

      img {
        display: block;
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        max-width: 19.875rem;
        margin-top: -.5rem;
      }
    }

    &__right {
      width: 50%;
      padding-top: 2.2rem;

      p {
        font-size: 1rem;
        margin-right: 0.375rem 0 1.45rem;
      }
    }

    &__header {
      font-size: 1.875rem;
    }
  }
  /* tablet: ends */
  .members {
    padding: 2.375rem 0 6.75rem;

    &__header {
      font-size: 1.875rem;
      margin-bottom: 1rem;
    }

    &__who {
      font-size: 1rem;
    }

    &__persons {
      padding: 0;
      margin: 3.875rem 0 0;
    }

    &__item {
      &:nth-child(1) {
        padding-right: 2rem;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__txt {
      strong {
        font-size: 1.125rem;
        padding: 0;
      }

      span {
        font-size: 1rem;
      }
    }
  }

  .attention {
    padding: 3rem 0;
    background-size: cover;

    .center {
      max-width: 29.25rem;
    }

    &__header {
      font-size: 3.125rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9375rem;
      text-align: left;
    }

    &__divider {
      margin: 1.75rem 0 3.375rem;
    }
  }

  .tool {
    padding: 3.125em 0;

    &__header {
      max-width: 36.75rem;
      margin: 0 auto 1rem;
      font-size: 1.875rem;
    }

    p {
      font-size: 1rem;
    }

    &__logic {
      padding: 1.75rem 0 2.625rem;

      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 41.75rem;
      }
    }
  }

  .steps {
    padding: 3.125rem 0 4.0625rem;
    background-size: 1800px auto;
    background-position: 60% 0;

    &__header {
      font-size: 1.875rem;
      margin-bottom: 3.4375rem;
    }

    &__txt {
      flex-direction: column;
    }

    &__column {
      padding: 0;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      &--1 {
        position: relative;
      }

      > div {
        padding-right: 0.5rem;
      }

      p {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }

      .num {
        font-size: 11.25rem;
      }

      &:nth-child(1) {
        flex-direction: row-reverse;

        .num {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }

    &__winners {
      padding-top: 3.125rem;

      strong {
        font-size: 1.875rem;
        display: block;
        padding-bottom: 1rem;
      }

      p {
        display: block;
        margin: 0;
      }
    }

    &__win {
      padding-top: 1.6875rem;
      font-size: 1.875rem;
    }
  }

  .bingo {
    padding: 3.125rem 0 3.75rem;

    &__main {
      img {
        display: block;
        width: 11.5rem;
        height: auto;
      }

      &__header {
        font-size: 2rem;
      }

      strong {
        font-size: 4.875rem;
        padding-bottom: 2.5625rem;
      }
    }

    &__items {
      padding-top: 2.625rem;
      max-width: 39.75rem;
    }

    &__item {
      svg {
        display: block;
        height: auto;
      }

      &--2 {
        font-size: 1.5rem;

        span {
          font-size: 2.625rem;
        }

        svg {
          width: 10.5625rem;
        }
      }

      &--1 {
        font-size: 1.0625rem;

        span {
          font-size: 3.25rem;
        }

        svg {
          width: 13.4375rem;
        }
      }

      &--3 {
        font-size: 1.1875rem;

        span {
          font-size: 2.3125rem;
        }

        svg {
          width: 9.625rem;
        }
      }
    }
  }

  .time {
    padding: 3.125rem 0 5.3125rem;

    &__header {
      font-size: 1.5625rem;
      padding-bottom: 2.8125rem;
    }

    &__list {
      &:after {
        left: calc(~"36% - 1.5px");
      }

      br {
        display: block;
      }

      li {
        font-size: 1.125rem;

        .date {
          width: 35%;
        }

        .event {
          width: 65%;
        }
      }
    }
  }

  .helpful {
    padding: 3.125rem 0 3.75rem;

    &__h {
      font-size: 1.875rem;
      padding-bottom: 2.625rem;
    }

    &__list {
      svg {
        display: block;
        height: auto;
      }

      li {
        padding: 0;
        width: 33.3%;

        span.link,
        span.txt {
          font-size: 0.875rem;
        }

        &:nth-child(1) {
          svg {
            width: 6.9375rem;
          }
        }

        &:nth-child(2) {
          svg {
            width: 5.3125rem;
          }
        }

        &:nth-child(3) {
          svg {
            width: 6.375rem;
          }
        }
      }
    }
  }

  .l-winners {
    padding: 3.125rem 0 3.4375rem;

    &__h {
      font-size: 1.5625rem;
      max-width: 28.4375rem;
      line-height: 1.4;
      margin: 0 auto;
      padding-bottom: 1.875rem;
    }

    &__list {
      max-width: 42rem;
      margin: 0 auto;

      br {
        display: block;
      }

      li {
        margin: 0 1rem;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      span {
        font-size: 0.9375rem;
        line-height: 1.3;
        padding-bottom: 0.625em;
      }
    }
  }

  .contacts {
    position: relative;
    padding-top: 7.25rem;

    &:before {
      position: absolute;
      width: 100%;
      height: 7.375rem;
      top: -1px;
      left: 0;
      margin: 0;
      background-size: 100% auto;
      background-position: center top;
    }

    &__img {
      display: block;
      height: auto;
      width: 100%;
      max-width: 27.8125rem;
    }

    &__btn {
      padding: 1.4375em 0 3.75rem;
    }

    &__who {
      a {
        font-size: 1.6875rem;

        img {
          margin-top: 0;
          width: 4.875rem;
          height: 4.875rem;
        }
      }
    }

    &__person {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      max-width: 32rem;
      margin: 0 auto;
      font-size: 1.375rem;
      overflow: hidden;
      padding-bottom: 3.875rem;

      img {
        display: block;
        height: auto;
        width: 5.875rem;
        margin: 0;
      }
    }

    &__policy {
      text-align: left;
      max-width: 32rem;
      margin: 0 auto;
    }
  }
}