/* general : start */

html {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

.menu {
  display: none;
}

.blood-mixin() {
  &:before {
    display: block;
    width: 120rem;
    height: 16rem;
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -60rem;
    background: url("../img/svg/bloody_drops.svg") no-repeat center center;
    background-size: 100% auto;
  }
}

body,
html {
  overflow-x: hidden;
}

body {
  padding-top: 4.5rem;
  background: #19232D;
  color: #fff;
}

.center {
  box-sizing: content-box;
  max-width: 72.5rem;
  padding: 0 1.25rem;
  margin: 0 auto;
  position: relative;
}

.register_btn {
  display: block;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(260.8deg, #FFC700 0.63%, #FE710D 94.44%);
  border-radius: 50px;
  text-align: center;
  width: 100%;

  &--header {
    font-size: 1rem;
    max-width: 16.375rem;
    height: 3rem;
    line-height: 3rem;
    z-index: 1;
  }

  &--main {
    font-size: 1.875rem;
    max-width: 22.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    box-shadow: 0 4px 0 rgba(255, 116, 116, 0.25);
    position: relative;

    .cursor {
      position: absolute;
      top: 1.625rem;
      right: -1.313rem;
    }
  }
}

.header {
  height: 4.5rem;
  background: #19232D;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &__sub {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  nav {
    max-width: 696px;
    width: 100%;
    z-index: 1;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      li {
        display: block;

        a {
          font-size: 1rem;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

.intro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__left {
    padding-top: 7rem;
    max-width: 31.25rem;

    &__txt {
      padding-bottom: 3.813rem;

      p {
        font-size: 1.4375rem;
        line-height: 1.4;
        font-weight: 400;
        margin: 2rem 0 0;
      }
    }
  }

  &__right {
    position: relative;
    padding-top: 5.375rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;

    &__face {
      margin-right: -9.625rem;

      &--tablet {
        display: none;
      }
    }

    &__btr {
      position: absolute;
      top: -3.1875rem;
      left: -12.0625rem;

      &--tablet {
        display: none;
      }
    }
  }
}

.timeout {
  padding-top: 4.5rem;
  font-size: 1.313rem;

  &__header {
    display: block;
    padding-bottom: 1.375rem;
  }

  &__date {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item {
    flex-shrink: 0;
    width: 4.313rem;
    height: 4.313rem;
    border-radius: 100%;
    border: 0.188rem solid #f30;
    margin-right: 1.625rem;
    text-align: center;
    display: flex;
    font-size: 1.875rem;
    font-weight: 300;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;

    span {
      display: block;
      font-size: 0.688rem;
      font-weight: 400;
    }

    &:last-child {
      margin: 0;
    }

    &:nth-child(1) {
      border-color: #43C62D;
    }

    &:nth-child(2) {
      border-color: #FFC700;
    }

    &:nth-child(3) {
      border-color: #FF720E;
    }

    &:nth-child(4) {
      border-color: #00CCFF;
    }
  }
}

.price {
  background: #F4553F url("./../img/svg/prize_bg.svg") no-repeat center center;
  background-size: 100% auto;
  padding: 4.85rem 0 7.625rem;

  .center {
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__main {
    margin: 0 auto;
    text-align: center;
  }

  &__header {
    font-weight: 300;
    font-size: 2.75rem;
    margin: -1rem 0 0;
  }

  &__money {
    font-weight: 900;
    color: #FFBC0E;
    font-size: 6.8125rem;
  }

  &__organizer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3rem;

    &__txt {
      padding-left: 3.125rem;
      font-size: 1.625rem;
      line-height: 1.4;
      text-align: left;

      span {
        color: #FFBC0E;
      }
    }
  }

  &__whois {
    list-style: none;
    padding: 5rem 0 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 960px;

    &__item {
      display: block;
      text-align: center;
      line-height: 1.4;
    }

    &__icon {
      display: block;
      padding-bottom: 2rem;
    }
  }
}

.task {
  position: relative;
  .blood-mixin;

  .center {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__left {
    img {
      position: absolute;
      top: -3.75rem;
      left: 5.688rem;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 3.125rem;
    margin: 0;
  }

  &__likes {
    margin-top: 3.75rem;
  }

  &__right {
    line-height: 1.4;
    padding-top: 5.2rem;
    width: 100%;
    max-width: 30.625rem;

    p {
      font-size: 1.563rem;
      margin: 0.375rem 0 2.25rem;
    }
  }
}

.members {
  padding: 4.375rem 0 12.5rem;

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    font-size: 3.125rem;
    font-weight: 700;
    margin: 0 0 2.5rem;
  }

  &__who {
    font-size: 1.5625rem;
    line-height: 1.4;
    margin: 0;
    text-align: center;
  }

  &__persons {
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  &__txt {
    text-align: center;
    display: block;
    width: 100%;
    max-width: 26.875rem;
    margin: 0 auto;
    padding-top: 1.75rem;

    strong {
      font-size: 1.6875rem;
      font-weight: 700;
      display: block;
      padding-bottom: 1rem;
    }

    span {
      font-size: 1.4375rem;
    }
  }
}

.attention {
  padding: 6.875rem 0;
  background: #974331 url("../img/pumpkin_bg.jpg") no-repeat center center;
  background-size: cover;
  text-align: center;

  .center {
    max-width: 65.125rem;
  }

  &__header {
    color: #FFC700;
    margin: 0;
    font-size: 6rem;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 2.5rem;
  }

  &__divider {
    margin: 6.375rem 0 5.125rem;
  }

  &__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  p {
    font-size: 1.5625rem;
    font-weight: 600;
    margin: 0;

    a {
      color: #FFC700;
      text-decoration: none;
    }
  }
}

.tool {
  padding: 6.875rem 0;
  text-align: center;

  &__header {
    font-weight: 700;
    font-size: 3.125rem;
    margin: 0 0 2.5rem;
  }

  p {
    font-size: 1.5625rem;
    margin: 0;
  }

  &__logic {
    padding: 7.75rem 0;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.steps {
  padding: 5rem 0 4.75rem;
  background: #4E3919 url("./../img/steps_bg.jpg") no-repeat center center;
  background-size: cover;

  .center {
    max-width: 81.875rem;
  }

  &__header {
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 1;
    margin: 0 0 3.95rem;
    text-align: center;
  }

  &__txt {
    display: flex;
    flex-direction: row;

    a {
      color: #FFC700;
      text-decoration: none;
    }
  }

  &__column {
    font-size: 1.4375rem;
    width: 50%;

    p {
      line-height: 1.4;
      margin: 0 0 2.3rem;
    }

    .accent {
      color: #FFB203;
    }

    .num {
      color: #FFB203;
      font-weight: 300;
      opacity: 0.7;
      font-size: 15rem;
      line-height: 1;
    }

    &--1 {
      padding-right: 1.5625rem;
      position: relative;

      .num {
        position: absolute;
        top: -2rem;
        left: -7rem;
      }
    }

    &--2 {
      padding-left: 1.5625rem;

      .num {
        display: block;
        text-align: center;
        margin-top: -1rem;
      }
    }
  }

  &__winners {
    text-align: center;
    color: #ffc700;
    max-width: 45rem;
    margin: 0 auto;

    strong {
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 1.2;
      padding-bottom: 2.875rem;
    }

    p {
      font-size: 1.4375rem;
      color: #FFC700;
    }
  }

  &__win {
    padding-top: 5.3125rem;
    margin: 0 auto;
    max-width: 37.5rem;
    font-size: 3.125rem;
    font-weight: 300;
    text-align: center;
  }
}

.bingo {
  padding: 2rem 0 5rem;
  background: #f4553f url("../img/svg/prize_bg.svg") no-repeat center center;
  background-size: 100% auto;

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-right: -2rem;
    }
  }

  &__header {
    margin: -1rem 0 1rem;
    line-height: 1;
    font-weight: 300;
    font-size: 2.75rem;
  }

  strong {
    color: #FFBC0E;
    font-size: 6.8125rem;
    line-height: 1;
    padding-bottom: 3rem;
  }

  &__items {
    padding-top: 6.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 57.5rem;
    margin: 0 auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;

    span {
      font-weight: 900;
    }

    &--1 {
      font-size: 2.125rem;

      span {
        font-size: 4.5rem;
      }
    }

    &--2 {
      font-size: 1.625rem;

      span {
        font-size: 3.5rem;
      }
    }

    &--3 {
      font-size: 1.5rem;

      span {
        font-size: 3.25rem;
      }
    }
  }
}

.time {
  padding: 5rem 0 7.5rem;
  background: #19232D;

  &__header {
    font-size: 2.75rem;
    line-height: 1;
    padding-bottom: 4.75rem;
    text-align: center;
    margin: 0;
    font-weight: 400;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    margin: 0 auto;
    position: relative;

    br {
      display: none;
    }

    &:after {
      width: 3px;
      content: '';
      display: block;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMyIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNSAwQTEuNSAxLjUgMCAwMTMgMS41djdhMS41IDEuNSAwIDExLTMgMHYtN0ExLjUgMS41IDAgMDExLjUgMHoiIGZpbGw9IiNmZmYiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgzdjE4SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+");
      background-repeat: repeat-y;
      background-position: top center;
      position: absolute;
      top: 15px;
      bottom: 15px;
      left: calc(~"50% - 1.5px");
    }

    .gold {
      color: #FFC632;
      text-shadow: 0 0 10px rgba(255, 188, 14, 0.55);
    }

    .blue {
      color: #00C9FF;
      text-shadow: 0 0 10px rgba(71, 171, 254, 0.64);
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.4375rem;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      .date {
        text-align: right;
        width: 50%;
      }

      .event {
        text-align: left;
        width: 50%;
      }

      .donut {
        font-size: 0;
        width: 1.25rem;
        height: 1.25rem;
        border: 0.3125rem solid transparent;
        margin: 0 1.25rem;
        border-radius: 100%;
        background: #19232d;
        flex-shrink: 0;
        z-index: 1;

        &.gold {
          border-color: #FFC632;
        }

        &.blue {
          border-color: #00C9FF;
        }
      }

      span {
        display: block;
      }
    }
  }
}

.helpful {
  background: #974331 url("../img/pumpkin_bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 5rem 0 7.5rem;

  &__h {
    text-align: center;
    margin: 0;
    font-size: 3.125rem;
    line-height: 1;
    font-weight: 700;
    padding-bottom: 2.625rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    li {
      padding: 0 2.4375rem;
      display: flex;
      flex-direction: column;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    span {
      display: block;
      text-align: center;

      &.txt {
        font-weight: 300;
        font-size: 1.25rem;
      }

      &.link {
        font-weight: 400;
        font-size: 1.125em;
      }
    }

    svg {
      display: block;
      margin: 0 auto 1.25rem;
    }
  }
}

.l-winners {
  padding: 6.5em 0 11rem;
  background: #f4553f url("../img/svg/prize_bg.svg") no-repeat center center;
  background-size: 100% auto;

  .center {
    max-width: 83.75rem;
  }

  &__h {
    margin: 0;
    text-align: center;
    color: #FFBC0E;
    font-size: 3.125rem;
    line-height: 1;
    padding-bottom: 4.75rem;
  }

  &__list {
    list-style: none;
    margin: 0 -1.8125rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    br {
      display: none;
    }

    span {
      display: block;
      font-size: 1.4375rem;
      padding-bottom: 1rem;
      text-align: center;
    }

    li {
      margin: 0 1.8125em;
    }

    img {
      display: block;
      width: 100%;
      max-width: 410px;
      height: auto;
      border-radius: 0.25rem;
    }
  }
}

.contacts {
  .blood-mixin;
  position: relative;
  background: #19232D;
  padding-top: 17rem;
  padding-bottom: 3rem;

  &__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 42.625rem;
  }

  &__btn {
    padding: 3.125rem 0 8rem;
    display: flex;
    justify-content: center;
  }

  &__who {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 51.25rem;
    margin: 0 auto;
    padding-bottom: 6.25rem;
  }

  &__item {
    overflow: hidden;
    color: #fff;
    text-decoration: none;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.3;

    span {
      font-weight: 700;
    }

    img {
      float: left;
      display: block;
      width: 100%;
      max-width: 5.25rem;
      margin-top: 0.45rem;
      margin-right: 1.25rem;
    }
  }

  &__person {
    text-align: center;
    font-size: 1.875rem;
    color: #fff;
    font-weight: 400;
    line-height: 1.2;

    strong {
      font-weight: 700;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    img {
      display: block;
      margin: 1.9rem auto 4.5rem;
      width: 100%;
      max-width: 148px;
      height: auto;
    }
  }

  &__policy {
    text-align: center;

    a {
      font-size: 1rem;
      line-height: 1.4;
      color: #fff;
      text-decoration: none;
    }
  }
}

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f4553f;
  z-index: 9;
  padding: 1rem 1.25rem;
  box-shadow: 0 0 2px 1px rgba(0,0,0, .3);

  &__wrapper {
    display: flex;
    max-width: 35.5rem;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__text {
    display: block;
    text-align: justify;
    margin: 0 0 1rem;
  }

  &__ok {
    display: block;
    background: #19232d;
    padding: 0.5rem 1rem;
    border-radius: 1.875rem;
    text-align: center;
    width: 10rem;
    cursor: pointer;
  }
}